import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tooltip, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography, Badge} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import PortalTooltip from '../../common/PortalTooltip';
import MenuListItem from './MenuListItem';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { faEnvelope, faChalkboardUser, faChevronDown, faChevronUp, faGraduationCap, faUsers, faPaw, faUserVneckHairLong} from '@fortawesome/pro-light-svg-icons';


class ExpandableMenuListItem extends Component {
    state={
        isMenuItemOpen: this.props.isMobile ? false: true,
    }

    onClick = () =>{
        this.setState({
            isMenuItemOpen: !this.state.isMenuItemOpen
        })
    }

    render() {
        return ( <React.Fragment>
            {/* //TODO: make this generic and pass in a list of items */}

            <ListItem onClick={this.onClick} disableGutters sx={{p:0, pl: '4px', display: 'block'}} className="expandedMenuItem">
                <ListItemButton id={"sideNav__button_" + this.props.title.replace(' ', '')} onClick={this.props.onClick} className={this.props.isOpen ? "justify-content-start" : "justify-content-center"}>
                    
                    {/* sidenav closed, show icon only */}
                    {!this.props.isSideNavOpen && <>
                        <Tooltip title={"Expand " + this.props.title} placement="right" arrow>
                            <ListItemIcon sx={{color: '#DADADA', minWidth: '1.75rem', pt:1, pb:1}} role="button">
                                <FontAwesomeIcon icon={this.props.icon} size={"lg"} fixedWidth />
                            </ListItemIcon>
                        </Tooltip>
                    </>}

                    {/* sidenav open, show icon and text */}
                    {this.props.isSideNavOpen && <>
                        <ListItemIcon sx={{color: '#DADADA', minWidth: '1.75rem'}}>
                            <FontAwesomeIcon icon={this.props.icon} size={"lg"} fixedWidth/>
                        </ListItemIcon> 
                        {/* <Badge variant={this.props.variant} badgeContent={this.props.badgeCount} color='error' max={10} className={"myOdu__iconBadge " + this.props?.variant}> */}
                            <ListItemText disableTypography sx={{ opacity: this.props.isSideNavOpen ? 1 : 0 }} 
                                primary={
                                <Typography sx={{ mr:1, fontWeight: '600', color: 'white' }}>
                                    {this.props.title}
                                </Typography>}
                            />
                        {/* </Badge> */}
                    </>}
                    {this.state.isMenuItemOpen ? 
                        <Tooltip id="sideNav__icon_expandMenuItem" title={"Expand " + this.props.title} placement="right" arrow>
                            <FontAwesomeIcon icon={faChevronUp} size={"xs"} fixedWidth /> 
                        </Tooltip>
                        : 
                        <Tooltip id="sideNav__icon_collapseMenuItem" title={"Collapse " + this.props.title} placement="right" arrow>
                            <FontAwesomeIcon icon={faChevronDown} size={"xs"} fixedWidth />
                        </Tooltip>
                    }
                </ListItemButton>

                <Collapse in={this.state.isMenuItemOpen} timeout="auto" unmountOnExit>
                    <List sx={{ml:0}} component="ul" disablePadding className="expandedMenuList">
                        {this.props.isAdvisor && <MenuListItem
                            title='Advisor'
                            isOpen={this.props.isSideNavOpen}
                            icon={faUserVneckHairLong}
                            isNewWindow="true"
                            onClick={() => {window.open('https://itsapps.odu.edu/advisor/', '_blank')}}
                        />}
                        <MenuListItem
                            title='Canvas'
                            isOpen={this.props.isSideNavOpen}
                            img='canvasWhiteOutline.svg'
                            isNewWindow="true"
                            onClick={() => {window.open('https://canvas.odu.edu/', '_blank')}}
                        />
                        <MenuListItem
                            title='Degree Works'
                            isOpen={this.props.isSideNavOpen}
                            icon={faGraduationCap}
                            isNewWindow="true"
                            onClick={() => {window.open('https://degree.odu.edu/', '_blank')}}
                        />
                        <MenuListItem
                            title='Gmail'
                            isOpen={this.props.isSideNavOpen}
                            img="gmailWhiteOutline.svg"
                            isNewWindow="true"
                            onClick={() => {window.open('https://mail.google.com/a/odu.edu', '_blank')}}
                        />
                        <MenuListItem
                            title='LeoOnline'
                            isOpen={this.props.isSideNavOpen}
                            img="leoOnlineWhite.svg"
                            isNewWindow="true"
                            onClick={() => {window.open('https://sso.odu.edu/ssomanager/c/SSB', '_blank')}}
                        />
                        <MenuListItem
                            title='Outlook'
                            isOpen={this.props.isSideNavOpen}
                            img="outlookWhiteOutline.svg"
                            isNewWindow="true"
                            onClick={() => {window.open('http://outlook.odu.edu/', '_blank')}}
                        />
                        <MenuListItem
                            title='Papers'
                            isOpen={this.props.isSideNavOpen}
                            img="papersWhite.svg"
                            isNewWindow="true"
                            onClick={() => {window.open('https://jobs.odu.edu/hr', '_blank')}}
                        />
                        <MenuListItem
                            title='Student360'
                            isOpen={this.props.isSideNavOpen}
                            img="student360WhiteOutline.svg"
                            isNewWindow="true"
                            onClick={() => {window.open('https://success.odu.edu/', '_blank')}}
                        />
                        <MenuListItem
                            title='Student Resources'
                            isOpen={this.props.isSideNavOpen}
                            icon={faPaw}
                            isNewWindow="true"
                            onClick={() => {window.open('https://www.odu.edu/sees/student-resources', '_blank')}}
                        />
                        <MenuListItem
                            title='Student Success Services'
                            isOpen={this.props.isSideNavOpen}
                            img="retainWhiteOutline.svg"
                            isNewWindow="true"
                            onClick={() => {window.open('https://odu.retain.io/', '_blank')}}
                        />
                    </List>
                </Collapse>
            </ListItem>
        </React.Fragment>);
    }
}

export default ExpandableMenuListItem;



