import React, {Component} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    IconButton,
    Typography,
    Box,
    Menu,
    MenuItem
} from '@mui/material';
import {
    faChevronDown,
    faEllipsisVertical,
    faGripDots
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import WidgetMenu from './WidgetMenu';

class Widget extends Component {
    state = {
        isExpand: true
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    writeTitle = () => {
       if(this.props.data.isTitleStylized){
        return <Stack direction="row" className="myOdu__stylizedText">
          
            {this.props?.data?.title?.map((word, index) => (
                 <Typography component="span" id={this.props.data.id + "_text_titleWord" + index} key={this.props.data.id + "_title_word_" + index}>{word}</Typography>
            ))}
            
            </Stack>
       }
       else return this.props.data.title;
    }

    isAccordionExpanded = () => {
        // return (this.props.isExpand ?? this.state.isExpand) || this.props.isDragging
        // return (this.state.isExpand || this.props.isDragging)
        return this.state.isExpand;
    }

    render() {
        return (
            <React.Fragment>
                <Accordion
                    expanded={this.isAccordionExpanded()}
                    elevation={0}
                    className={'myOdu__widget ' + (this.props.className || '')}
                    id={this.props.data.id + '__accordion'}
                >
                    {/* To make it library independent update provided props */}
                    <div  {...this.props.provided?.dragHandleProps}>
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    // size="lg"
                                    onClick={() => this.toggleExpand()}
                                    fixedWidth
                                    id={this.props.data.id + "__chevron_accordionSummary"}
                                />
                            }
                            id={this.props.data.id + '__accordionSummary'}
                            aria-controls={
                                this.props.data.id + '__accordionDetails'
                            }
                            sx={{flexDirection: 'row-reverse', flexGrow: 1}}
                            className={"header"}
                            justifyContent="center"
                        >
                            <Typography
                                id={this.props.data.id +"__title" }
                                component="h2"
                                // variant="h4"
                                onClick={() => this.toggleExpand()}
                                className="myOdu__widgetTitle"
                            >
                                {this.writeTitle()}
                            </Typography>
                            <Box
                                id={ this.props.data.id+ "__box" }
                                sx={{flexGrow: 1}}
                                onClick={() => this.toggleExpand()}
                            />
                            {this.props.data.menu && (
                                <Box className="widgetMenuWrapper" id={ this.props.data.id + "__widgetMenu_wrapper" }>
                                    <WidgetMenu
                                        data={{
                                            title: this.props.data.title,
                                            id: this.props.data.id,
                                            isMenuDisabled: this.props.data.isMenuDisabled
                                        }}
                                        menu={this.props.data.menu}
                                    />
                                </Box>
                            )}
                        </AccordionSummary>
                    </div>
                    <AccordionDetails
                        id={this.props.data.id + '__accordionDetails'}
                        className={"myOdu__widgetDetails " 
                            + (this.props.hasScrollY ? ' scrollY' :'')
                            + (this.props.hasScrollX ? ' scrollX' :'')
                        }
                    >
                        {this.props.children}
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        );
    }
}

export default Widget;
