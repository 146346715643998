import React, {Component} from 'react';
import Widget from '../common/Widget';
// TODO: enable in V1.0+
// import { FavoriteLocationConfigs, MyMealPlan } from './config';
// import Place from '../common/PlaceSummary';
// import PlaceCard from '../common/PlaceCard';
// import { Container, Row, Col } from 'reactstrap';
import {
    Card,
    Link,
    Box,
    Grid,
    Stack,
    Typography,
    Divider,
    List,
    ListItem,
    Button,
    Tooltip
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faForkKnife,
    faUserChef,
    faHeart,
    faChevronDown,
    faTriangleExclamation,
    faDiamondExclamation
} from '@fortawesome/pro-light-svg-icons';
import {myDining} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import {connect} from 'react-redux';
import {
    saveBillToStudentAccount,
    saveFlexPoints,
    saveLaundryAccount,
    saveMealPlan,
    saveMonarchPlusAccount
} from './actions';
import {mealsThreshold, flexPointsThreshold} from '../common/constants';
import PortalTooltip from '../common/PortalTooltip';

class MyDining extends Component {
    state = {
        isExpand: true,
        isDetailsExpand: true,
        swipeReset: 'Academic Year',
        myDiningData: {}
        // menu: [
        //     {id: 'myDining_edit', title:'Edit Favorite Locations'},
        //     {id: 'myDining_hide', title: 'Hide'}
        // ]
    };

    componentDidMount = () => {
        getData(myDining).then(myDiningData => {
            this.props.saveBillToStudentAccount(
                myDiningData.balance.billToStudentAccount
            );
            this.props.saveFlexPoints(myDiningData.balance.flexPointsService);
            this.props.saveLaundryAccount(myDiningData.balance.laundryService);
            this.props.saveMealPlan(myDiningData.balance.mealPlan);
            this.props.saveMonarchPlusAccount(
                myDiningData.balance.monarchPlusService
            );
        });
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    toggleDetailsExpand = () => {
        this.setState({isDetailsExpand: !this.state.isDetailsExpand});
    };

    isFlexPointBalanceLow = () => {
        return (
            Number(this.props.flexPoints?.amount) < Number(flexPointsThreshold)
        );
    };

    isMealPlanBalanceLow = () => {
        return Number(this.props.mealPlan?.amount) < Number(mealsThreshold);
    };

    getMealBalanceClass = () => {
        return this.isMealPlanBalanceLow() ? 'myOdu__lowBalance' : '';
    };

    render() {
        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized, 
                        menu: this.state.menu
                    }}
                    {...this.props}
                    className="myOdu__myDining"
                >
                    <Stack direction="row" sx={{pt: 0}}>
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faUserChef}
                            style={{
                                marginRight: '0.5rem',
                                marginTop: '0.25rem'
                            }}
                        />
                        <Link
                            id="myDining__link_diningLocations"
                            variant="h6"
                            href="https://olddominion.campusdish.com/LocationsAndMenus"
                            underline="hover"
                        >
                            Dining Locations and Menus
                        </Link>
                    </Stack>
                    <Typography component="h3" variant="h6" sx={{mt: 1}}>
                        {/* uncomment when myDining link availabe */}
                        {/* {this.state?.title ? */}
                        {/* <><FontAwesomeIcon fixedWidth icon={faForkKnife} style={{marginRight: '0.25rem', marginTop: '0.25rem'}}/> */}
                        {/* <Link id="myDining__link_myMealPlan" href="#" underline={"hover"}>My Meal Plan</Link></> */}
                        {/* :  */}
                        <>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faForkKnife}
                                style={{
                                    marginRight: '0.25rem',
                                    marginTop: '0.25rem'
                                }}
                            />{' '}
                            My Meal Plan
                        </>
                        {/* }  */}
                    </Typography>
                    {this.props?.title ? (
                        <>
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={2}
                                alignItems={'stretch'}
                                justifyContent={'left'}
                                style={{marginTop: '0.1rem'}}
                            >
                                <Grid
                                    item
                                    xxl={6}
                                    xl={12}
                                    md={6}
                                    sm={6}
                                    sx={{width: '100%'}}
                                >
                                    <Box alignContent="center" className="mealPlan" >
                                        <Typography
                                            component="p"
                                            variant="h3"
                                            className={this.getMealBalanceClass()}
                                            sx={{display: 'inline'}}
                                        >
                                            {this.props.mealPlan &&
                                            Object.keys(this.props.mealPlan)
                                                ?.length > 0 ? (
                                                this.props.mealPlan.amount
                                            ) : (
                                                <PortalTooltip title="Meal Plan Not Found">
                                                    ---
                                                </PortalTooltip>
                                            )}
                                        </Typography>

                                        {this.isMealPlanBalanceLow() && (
                                            <FontAwesomeIcon
                                                className="myOdu__lowBalance"
                                                icon={faTriangleExclamation}
                                                fixedWidth
                                                size="lg"
                                                style={{
                                                    display: 'inline',
                                                    marginBottom: '0.25rem'
                                                }}
                                            />
                                        )}
                                        <Typography component="h4" variant="p">
                                            {this.props.mealPlan &&
                                            this.props.mealPlan?.amount == 1
                                                ? 'Meal'
                                                : 'Meals '}{' '}
                                            Left this {this.state.swipeReset}
                                        </Typography>

                                        {/* //TODO: enable in version 1.1+ 
                                {(this.state.mealExchangesLeft == 0) ? 
                                    <Typography component="p" variant="smaller" sx={{display: 'inline'}}> With <span style={{fontWeight: 600}}>no</span> Meal Exchanges left</Typography>
                                : <>
                                    <Typography component="p" variant="smaller" sx={{lineHeight: '120%', pt: 1}}>With up to <span style={{fontWeight: 600, px:"0.25rem"}}>{this.state.mealExchangesLeft}&nbsp;</span> 
                                        <Link underline={"always"} href="https://olddominion.campusdish.com/-/media/Local/Higher-Education/GroupC/OldDominion/Files/Fall-2023-Meal-Exchange-Menu.ashx">as Meal Exchanges</Link>
                                    </Typography>
                                </>} */}
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    xxl={6}
                                    xl={12}
                                    md={6}
                                    sm={6}
                                    sx={{width: '100%'}}
                                >
                                    <Box className="flexPoints" > 
                                        <Typography
                                            className={
                                                this.isFlexPointBalanceLow() &&
                                                this.isFlexPointBalanceLow()
                                                    ? 'myOdu__lowBalance'
                                                    : ''
                                            }
                                            component="p"
                                            variant="h3"
                                            sx={{display: 'inline'}}
                                        >
                                            {this.props.flexPoints &&
                                            Object.keys(this.props.flexPoints)
                                                .length > 0 ? (
                                                '$' +
                                                this.props.flexPoints.amount
                                            ) : (
                                                <PortalTooltip title="Flex Points Not Found">
                                                    ---
                                                </PortalTooltip>
                                            )}
                                        </Typography>
                                        {this.isFlexPointBalanceLow() && (
                                            <FontAwesomeIcon
                                                icon={faTriangleExclamation}
                                                className="myOdu__lowBalance"
                                                fixedWidth
                                                size="lg"
                                                style={{
                                                    display: 'inline',
                                                    marginBottom: '0.25rem'
                                                }}
                                            />
                                        )}

                                        <Typography
                                            component="h4"
                                            variant="p"
                                            sx={{pt: 0}}
                                        >
                                            Flex Points Left this Academic Year
                                            <>
                                                <br />
                                                <PortalTooltip>
                                                    <Button
                                                        id="myDining__button_depositFlexPoints"
                                                        className="myOdu__button secondary myDiningButton"
                                                        target="_blank"
                                                        href="https://csgoldweb.odu.edu/purchase.html"
                                                        variant="outlined"
                                                        sx={{mt: 1}}
                                                    >
                                                        Make Deposit
                                                    </Button>
                                                </PortalTooltip>
                                            </>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Typography>No meal plan found.</Typography>
                    )}

                    {/* //TODO: enable in version 1.1+
                <Stack direction="row" sx={{pt:2}}>
                    <FontAwesomeIcon icon={faHeart} style={{marginRight: '0.5rem', marginTop: '0.25rem'}}/>
                    <Typography component="h4" variant="h6">Favorite Locations</Typography>
                </Stack>

                <List>
                {FavoriteLocationConfigs.map(location =>
                    <ListItem divider sx={{ml: 0, p:0}} key={this.state.widgetId + "_favorites_" + location.id}>
                        <PlaceCard data={{location: location}} widgetId={this.state.widgetId} />
                    </ListItem>
                )}
                </List> */}
                </Widget>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        billToAccount: state.myDiningReducer.billToAccount,
        flexPoints: state.myDiningReducer.flexPoints,
        laundryAccount: state.myDiningReducer.laundryAccount,
        mealPlan: state.myDiningReducer.mealPlan,
        monarchPlus: state.myDiningReducer.monarchPlus
    };
};

const mapDispatchToProps = dispatch => ({
    saveBillToStudentAccount: billToAccount =>
        dispatch(saveBillToStudentAccount(billToAccount)),
    saveFlexPoints: flexPoints => dispatch(saveFlexPoints(flexPoints)),
    saveLaundryAccount: laundryAccount =>
        dispatch(saveLaundryAccount(laundryAccount)),
    saveMealPlan: mealPlan => dispatch(saveMealPlan(mealPlan)),
    saveMonarchPlusAccount: monarchPlus =>
        dispatch(saveMonarchPlusAccount(monarchPlus))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDining);
