import React, {Component} from 'react';
import {Box, List, Link, Typography, Tooltip} from '@mui/material/';
import NewsItem from './NewsItem';
import PortalTooltip from '../common/PortalTooltip.js';
import {cleanUpID} from '../common/utils.js';

class UniversityNews extends Component {
    render() {
        return (
            <Box
                id={this.props.data.widgetId + '__div_universityNewsWrapper'}
                className="scrollWrapper pe-0 pe-sm-2"
            >
                <Box
                    className="wrapper"
                    id={this.props.data.widgetId + '__box_universityNews'}
                >
                    <PortalTooltip
                        id={this.props.data.widgetId + '__tooltip_universityNews'}
                    >
                        <Link
                            id={this.props.data.widgetId + '__link_universityNews'}
                            href="http://www.odu.edu/news"
                            target="_blank"
                            underline="hover"
                            variant="small"
                            sx={{mr: 1, mb:2}}
                        >
                            University News
                        </Link>
                    </PortalTooltip>
                </Box>

                {!this.props.news.includes(
                    'You need to enable JavaScript to run this app.'
                ) ? (
                    this.props.news && this.props.news.length ? (
                        <List className="newsList" id={this.props.data.widgetId +"__list_universityNews"}>
                            {this.props.news.map((newsItem, index) => (
                                <NewsItem
                                    data={newsItem}
                                    index={index}
                                    widgetId={this.props.data.widgetId}
                                    key={'universityNews_' + index}
                                />
                            ))}
                        </List>
                    ) : (
                        <Typography component="p" variant="small" id={this.props.data.widgetId + "__noNews_universityNews"}>
                            No news found.
                        </Typography>
                    )
                ) : (
                    <Typography component="p" variant="small" id={this.props.data.widgetId + "__errorNews_universityNews"}>
                        Error retrieving news.
                    </Typography>
                )}
            </Box>
        );
    }
}

export default UniversityNews;
