import {Box, Button, Grid, Tab, Tabs} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import ScheduleMeeting from './ScheduleMeeting/index.js';
import {connect} from 'react-redux';
import {ZoomTabMapping} from './config.js';
import {getData} from '../../../../../DataAccessLayer/index.js';
import {cct, terms} from '../../../../../DataAccessLayer/services.js';
import moment from 'moment';
import Meetings from './Meetings/index.js';
import {FormDropDown} from '../../../../common/Form/FormDropdown.js';
import {WithSmallScreen} from '../../../../common/WithSmallScreen.js';

class Zoom extends Component {
    state = {
        activeTab: 1
    };

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({
            activeTab
        });
    };

    componentDidMount() {
        if (!this.props.terms[0]) {
            this.getTerms();
        }
    }

    async getTerms() {
        try {
            await getData(terms, true);
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const isTeaching = this.props.course.isTeaching;
        const isTaking = this.props.course.isTaking;

        const isLastSemCourse =
            this.props.course.SSBSECT_TERM_CODE === this.props.terms[0]?.code;

        const tabsDetails = [
            {label: 'Current/Upcoming', value: ZoomTabMapping.currentMeeting},
            {label: 'Past Meetings', value: ZoomTabMapping.pastMeeting}
        ];
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h4>Zoom Meetings</h4>
                    </Col>
                </Row>
                {(!this.props.isSmall && (
                    <Box
                        sx={{
                            width: '100%',
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}
                    >
                        {
                            <Grid
                                container
                                justifyContent={'space-between'}
                                spacing={1}
                            >
                                <Grid item xs={12} md={6}>
                                    {!this.props.isSmall && (
                                        <Tabs
                                            value={this.state.activeTab}
                                            onChange={this.onTabChange}
                                            aria-label="secondary tabs example"
                                        >
                                            {tabsDetails.map(details => {
                                                return (
                                                    <Tab
                                                        value={details.value}
                                                        label={details.label}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                    )}
                                </Grid>
                                <Grid item>
                                    {isTeaching &&
                                        !isTaking &&
                                        !isLastSemCourse && (
                                            <Button
                                                className="myOdu__button scheduleMeeting"
                                                size="small"
                                                variant="outlined"
                                                style={{marginBottom: 6}}
                                                onClick={() =>
                                                    this.props.toggleIsScheduleMeeting(
                                                        true
                                                    )
                                                }
                                            >
                                                Schedule Meeting
                                            </Button>
                                        )}
                                </Grid>
                            </Grid>
                        }
                    </Box>
                )) || (
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                width: '100%',
                                borderBottom: 1,
                                borderColor: 'divider'
                            }}
                        >
                            {isTeaching && !isTaking && !isLastSemCourse && (
                                <Button
                                    className="myOdu__button scheduleMeeting"
                                    size="small"
                                    variant="outlined"
                                    style={{marginBottom: 6}}
                                    onClick={() =>
                                        this.props.toggleIsScheduleMeeting(true)
                                    }
                                    fullWidth
                                >
                                    Schedule Meeting
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormDropDown
                                options={tabsDetails}
                                labelGridProps={{xs: 0, md: 0}}
                                inputGridProps={{xs: 12, md: 0}}
                                onChange={event =>
                                    this.onTabChange(_, event.target.value)
                                }
                                id="select-meetings-type"
                                value={this.state.activeTab}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                )}
                {this.state.activeTab == ZoomTabMapping.currentMeeting && (
                    <Meetings
                        isLastSemCourse={isLastSemCourse}
                        meetings={this.props.currentMeetings}
                        activeTab={this.state.activeTab}
                        onTabChange={this.onTabChange}
                        openClassSchedule={this.props.openClassSchedule}
                        openAdhocSchedule={this.props.openAdhocSchedule}
                        isClassMeetingsScheduled={
                            this.props.isClassMeetingsScheduled
                        }
                    />
                )}
                {this.state.activeTab == ZoomTabMapping.pastMeeting && (
                    <Meetings
                        isPastMeetings={true}
                        isLastSemCourse={isLastSemCourse}
                        meetings={this.props.pastMeetings}
                        activeTab={this.state.activeTab}
                        onTabChange={this.onTabChange}
                        openClassSchedule={this.props.openClassSchedule}
                        openAdhocSchedule={this.props.openAdhocSchedule}
                        isClassMeetingsScheduled={
                            this.props.isClassMeetingsScheduled
                        }
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        terms: state.termsReducer.terms,
        currentMeetings: state.CCTReducer.meetings.currentMeetings,
        pastMeetings: state.CCTReducer.meetings.pastMeetings,
        isClassMeetingsScheduled: state.CCTReducer.meetings.isClassMeetingsScheduled
    }
}
 
export default connect(mapStateToProps)(WithSmallScreen(Zoom));
