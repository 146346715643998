import React, {Component} from 'react';
import Widget from '../common/Widget.js';
import NewsTabs from './NewsTabs.js';
import UniversityNews from './UniversityNews.js';
import {getDataPublic} from '../../DataAccessLayer/index.js';
import {news} from '../../DataAccessLayer/services.js';
import {truncate} from 'lodash';
import { connect } from 'react-redux';
import { getUser, isUserEvmcStaff, isUserMedical } from '../Layout/utils.js';

class News extends Component {
    state = {
        // isExpand: false,
        //TODO: enable in v1.0+
        // menu:[
        //     {id: "myNews_editSubscriptions", title: "Edit Subscriptions"},
        //     {id: "myNews_hide", title: "Hide"},
        // ],
        selectedTab: 1,
        universityNews: []
    };

    componentDidMount() {
        this.loadNews();
    }

    loadNews = () => {
        getDataPublic(news).then(universityNews => {
            this.setState({
                universityNews
            });
        });
    };

    render() {

        const isMedicalUser = isUserMedical(getUser(this.props.user, this.props.impersonation));

        return (
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized, 
                        menu: this.state.menu
                    }}
                    hasScrollY
                    isExpand={this.state.isExpand}
                    {...this.props}
                    className="myOdu__myNews"
                >
                    {/* //TODO: uncomment when evms pulse news is ready */}
                    {/* <NewsTabs data={{widgetId: this.state.widgetId}} news={this.state.universityNews} selectedTab = {isMedicalUser ? 1 : 0} /> */}
                    <UniversityNews data={{widgetId: this.props.widget.id}} news={this.state.universityNews} />
                </Widget>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        setup: state.setupReducer.setup,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

export default connect(mapStateToProps)(News);
