import { SAVE_PREFERENCES, SAVE_SETUP } from "./actions";

const initialStatePreferences = {
    preferences: {},
}

const initialStateSetup = {
    setup: {}
}

//needs to persist across pages
export const preferencesReducer = (state = initialStatePreferences, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_PREFERENCES: {
            return {...state, preferences: {...payload.preferences}}
        }
        default:
            return state;
    }
}

//does not needs to persist across pages
export const setupReducer = (state = initialStateSetup, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_SETUP: {
            return {...state, setup: {...payload.setup}}
        }
        default:
            return state;
    }
}