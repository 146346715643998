import {Grid, Skeleton, Typography} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import ContactCard from '../common/ContactCard';
import {saveContactsUpdate, toggleContactsDragging} from './actions';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {toast} from 'react-toastify';

const grid = 8;

class List extends Component {
    state = {};

    //Θ(1) style for DnD list
    getListStyle = isDraggingOver => ({});

    //Θ(1) style for DnD item
    getItemStyle = (isDragging, draggableStyle) => ({
        paddingTop: 8,

        // styles we need to apply on draggables
        ...draggableStyle
    });

    //Θ(1) filter out contact to be deleted and update
    onDelete = data => {
        this.props.saveContactsUpdate(
            this.props.contacts
                .map(contact => contact.midas)
                .filter(contact => contact != data.midas)
        );
        toast.success(
            'Contact for ' +
                data.lastName.toUpperCase() +
                ', ' +
                data.firstName.toUpperCase() +
                ' deleted'
        );
    };

    //O(N) Where N is the number of array elements
    // reorder array according to DnD
    onReorder = dragged => {
        if (dragged.destination) {
            let contacts = this.props.contacts;

            const [removed] = contacts.splice(dragged.source.index, 1);
            contacts.splice(dragged.destination.index, 0, removed);

            this.props.saveContactsUpdate(
                contacts.map(contact => contact.midas)
            );
        }
        this.props.toggleContactsDragging();
    };

    render() {
        return (
            <React.Fragment>
                <Typography
                    id={this.props.id + '__text_contactList'}
                    variant="h4"
                    className="pt-3"
                >
                    Contact List
                </Typography>

                <DragDropContext
                    id={this.props.id + '__div_dragDropContext'}
                    onDragEnd={this.onReorder}
                    onDragStart={this.props.toggleContactsDragging}
                >
                    <Droppable
                        id={this.props.id + '__div_droppableField'}
                        droppableId="droppable"
                    >
                        {(provided, snapshot) => (
                            <div
                                id={
                                    this.props.id +
                                    '__div_draggableField_dropper'
                                }
                                style={this.getListStyle(
                                    snapshot.isDraggingOver
                                )}
                            >
                                {this.props.contacts?.map((contact, index) => (
                                    <Draggable
                                        id={
                                            this.props.id +
                                            '__div_draggableField_' +
                                            contact.midas
                                        }
                                        key={contact.midas}
                                        draggableId={contact.midas}
                                        index={index}
                                        isDragDisabled={this.props.isLoading}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                id={
                                                    this.props.id +
                                                    '__div_reducer_' +
                                                    contact.midas
                                                }
                                                ref={provided.innerRef}
                                                style={this.getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                {!this.props.isLoading ? (
                                                    <ContactCard
                                                        id={
                                                            this.props.id +
                                                            'contactCard'
                                                        }
                                                        midas={contact.midas}
                                                        data={contact}
                                                        onDelete={this.onDelete}
                                                        isDisabled={
                                                            this.props.isLoading
                                                        }
                                                        isDelete
                                                    />
                                                ) : (
                                                    <Skeleton
                                                        id={
                                                            this.props.id +
                                                            '__listSkeleton_' +
                                                            index
                                                        }
                                                        variant="rectangular"
                                                        height={80}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                <span id={this.props.id + '__text_noContact'}>
                                    {this.props.contacts.length == 0 &&
                                        'No contacts found'}
                                </span>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.myContactsReducer.contacts,
        results: state.myContactsReducer.results,
        isLoading: state.myContactsReducer.isLoading
    };
};

const mapDispatchToProps = dispatch => ({
    saveContactsSearch: search => dispatch(saveContactsSearch(search)),
    saveContactsUpdate: updates => dispatch(saveContactsUpdate(updates)),
    toggleContactsDragging: () => dispatch(toggleContactsDragging())
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
