import {
    adminGroupName,
    MAIN_BRANCH,
    powerUserGroupName,
    advisorRoleName,
    studentRoleName,
    facultyRoleName,
    staffRoleName,
    medicalStudentRoleName,
    healthProfessionsStudentRoleName, 
    medicalResidentRoleName,
    evmcRoleName,
    evmcFacultyRoleName,
    evmcStaffRoleName,
    evhscRoleName,
    evhscFacultyRoleName,
    evhscStaffRoleName    
} from '../common/constants';

/**
 * Returns loggedin user details or impersonated user details 
 * @param user
 * @param impersonation
 * @returns {object}
 */
export const getUser = (user, impersonation) => {
    return impersonation.isImpersonating ? impersonation : user
}

export const isProduction = () => {
    const isProduction = process.env.REACT_APP_GIT_BRANCH == MAIN_BRANCH;
    return isProduction;
};

/**
 * Returns true if the given user is an admin based on MIDAS/Shib group membership
 * @param user
 * @returns {boolean}
 */
export const isUserAdmin = user => {
    const isAdmin = adminGroupName in (user?.groups ?? {});
    return isAdmin;
};

/**
 * Returns true if the given user is a power user (content admin) based on MIDAS/Shib group membership
 * @param user
 * @returns {boolean}
 */
export const isUserPowerUser = user => {
    const isPowerUser = powerUserGroupName in (user?.groups ?? {});
    return isPowerUser;
};

/**
 * Returns true if the given user is a student based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserStudent = user => {
    const isStudent = studentRoleName in (user?.roles ?? {});
    return isStudent;
};

/**
 * Returns true if the given user is faculty based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserFaculty = user => {
    const isFaculty = facultyRoleName in (user?.roles ?? {});
    return isFaculty;
};

/**
 * Returns true if the given user is staff based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserStaff = user => {
    const isStaff = staffRoleName in (user?.roles ?? {});
    return isStaff;
};

/**
 * Returns true if the given user is at least one of faculty or staff
 * @param user
 * @returns {boolean}
 */
export const isUserFacultyStaff = user => {
    return isUserFaculty(user) || isUserStaff(user);
};

/**
 * Returns true if the given user is at least one of faculty or staff
 * @param user
 * @returns {boolean}
 */
export const isUserAdvisor = user => {
    const isAdvisor = advisorRoleName in (user?.roles ?? {});
    return isAdvisor;
};

/**
 * Returns true if the given user is a medical student based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserMedicalStudent = user => {
    const isMedicalStudent = medicalStudentRoleName in (user?.roles ?? {});
    return isMedicalStudent;
};

/**
 * Returns true if the given user is a health professions student based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserHealthProfessionsStudent = user => {
    const isHealthProfessionsStudent =
        healthProfessionsStudentRoleName in (user?.roles ?? {});
    return isHealthProfessionsStudent;
};

/**
 * Returns true if the given user is a health professions student based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserMedicalResident = user => {
    const isMedicalResident =
        medicalResidentRoleName in (user?.roles ?? {});
    return isMedicalResident;
};

/**
 * Returns true if the given user is evmc based on MIDAS/Shib roles
 * EVMC is specifically the campus located in Fort Norfolk
 * @param user
 * @returns {boolean}
 */
export const isUserEvmc = user => {
    const isEvmc = evmcRoleName in (user?.roles ?? {});
    return isEvmc;
};

/**
 * Returns true if the given user is evmc fac based on MIDAS/Shib roles
 * EVMC is specifically the campus located in Fort Norfolk
 * @param user
 * @returns {boolean}
 */
export const isUserEvmcFaculty = user => {
    const isEvmcFaculty = evmcFacultyRoleName in (user?.roles ?? {});
    return isEvmcFaculty;
};

/**
 * Returns true if the given user is evmc staff based on MIDAS/Shib roles
 * EVMC is specifically the campus located in Fort Norfolk
 * @param user
 * @returns {boolean}
 */
export const isUserEvmcStaff = user => {
    const isEvmcStaff = evmcStaffRoleName in (user?.roles ?? {});
    return isEvmcStaff;
};

/**
 * Returns true if the given user is evhsc based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserEvhsc = user => {
    const isEvhsc = evhscRoleName in (user?.roles ?? {});
    return isEvhsc;
};

/**
 * Returns true if the given user is evhsc fac based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserEvhscFaculty = user => {
    const isEvhscFaculty = evhscFacultyRoleName in (user?.roles ?? {});
    return isEvhscFaculty;
};

/**
 * Returns true if the given user is evhsc staff based on MIDAS/Shib roles
 * @param user
 * @returns {boolean}
 */
export const isUserEvhscStaff = user => {
    const isEvhscStaff = evhscStaffRoleName in (user?.roles ?? {});
    return isEvhscStaff;
};

/**
 * Returns true if the given user is from medical
 * @param user
 * @returns {boolean}
 */
export const isUserMedical = user => {
    const isMedical =   isUserMedicalStudent(user) || isUserHealthProfessionsStudent(user) || isUserMedicalResident(user) ||
                        isUserEvmc(user) || isUserEvmcStaff(user) || isUserEvmcFaculty(user) ||
                        isUserEvhsc(user) || isUserEvhscFaculty(user) || isUserEvhscStaff(user);

    return isMedical;
};