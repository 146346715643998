import { SAVE_BILL_TO_STUDENT_ACCOUNT, SAVE_FLEX_POINTS, SAVE_LAUNDRY_ACCOUNT, SAVE_MEAL_PLAN, SAVE_MONARCH_PLUS } from "./actions";

const initialStateMyDining = {
    billToAccount: {},
    flexPoints: {},
    laundryAccount: {},
    mealPlan: {},
    monarchPlus: {}    
}

export const myDiningReducer = (state = initialStateMyDining, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_BILL_TO_STUDENT_ACCOUNT: {
            return {...state, billToAccount: payload.billToAccount}
        }
        case SAVE_FLEX_POINTS: {
            return {...state, flexPoints: payload.flexPoints}
        }
        case SAVE_LAUNDRY_ACCOUNT: {
            return {...state, laundryAccount: payload.laundryAccount}
        }
        case SAVE_MEAL_PLAN: {
            return {...state, mealPlan: payload.mealPlan}
        }
        case SAVE_MONARCH_PLUS: {
            return {...state, monarchPlus: payload.monarchPlus}
        }
        default:
            return state;
    }
}
