export const SAVE_BILL_TO_STUDENT_ACCOUNT = 'SAVE_BILL_TO_STUDENT_ACCOUNT';
export const SAVE_FLEX_POINTS = 'SAVE_FLEX_POINTS';
export const SAVE_LAUNDRY_ACCOUNT = 'SAVE_LAUNDRY_ACCOUNT';
export const SAVE_MEAL_PLAN = 'SAVE_MEAL_PLAN';
export const SAVE_MONARCH_PLUS = 'SAVE_MONARCH_PLUS';

export const saveBillToStudentAccount = (billToAccount) => ({
    type: SAVE_BILL_TO_STUDENT_ACCOUNT,
    payload: { billToAccount }
});

export const saveFlexPoints = (flexPoints) => ({
    type: SAVE_FLEX_POINTS,
    payload: { flexPoints }
});

export const saveLaundryAccount = (laundryAccount) => ({
    type: SAVE_LAUNDRY_ACCOUNT,
    payload: { laundryAccount }
});

export const saveMealPlan = (mealPlan) => ({
    type: SAVE_MEAL_PLAN,
    payload: { mealPlan }
});

export const saveMonarchPlusAccount = (monarchPlus) => ({
    type: SAVE_MONARCH_PLUS,
    payload: { monarchPlus }
});
