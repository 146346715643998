import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Grid } from '@mui/material';
import BalanceBox from './BalanceBox';
import { connect } from 'react-redux';
import { cardCenterMonarch, studentAccount } from '../common/constants';
import { myBalances, terms } from '../../DataAccessLayer/services';
import { getData } from '../../DataAccessLayer';

class MyBalances extends Component {
    state = {
        isExpand: true,
        widgetId: 'widget__myBalances',
        isDisplayLaundryBalance: false,
        isDisplayMonarchBalance: false,
        isDisplayTuitionBalance: false,
        balanceDue: 0,
        currentTerm: '',
        altAttribute: ''
    };

    toggleIsDisplayLaundryBalance = () => {
        this.setState({
            isDisplayLaundryBalance: !this.state.isDisplayLaundryBalance
        });
    };

    toggleIsDisplayMonarchBalance = () => {
        this.setState({
            isDisplayMonarchBalance: !this.state.isDisplayMonarchBalance
        });
    };

    toggleIsDisplayTuitionBalance = () => {
        this.setState({
            isDisplayTuitionBalance: !this.state.isDisplayTuitionBalance
        });
    };

    titleCase = (str) => {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    componentDidMount = () => {
        getData(myBalances).then(myBalancesData => {
            this.setState({
                balanceDue: myBalancesData.balances?.amount
            });
        })
        getData(terms).then(termsData => {
            const currenTerm = termsData.terms.filter(term => { return term.relativeTerm === 'CURRENT_TERM' })
            this.setState({ currentTerm: "Tuition - " + currenTerm[0].title, altAttribute: currenTerm[0].title + " Balance Due" })
        })
    }

    render() {
        return (
            <div id={"myBalances__div_balanceMainCard"}>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                    }}
                    hasScrollX={true}
                    className="myOdu__myBalances"
                    {...this.props}
                >
                    <div id={this.props.widget.id + "__wrapper"} className="scrollWrapper">
                        <Grid id={this.props.widget.id + "__grid_balance"}
                            sx={{ width: '100%' }}
                            container
                            className=""
                            wrap="nowrap"
                            direction="row"
                            rowSpacing={2}
                        >
                            <Grid id={"myBalances__gridItem_tuitionBalance"} item className="balanceGridItem">
                                <BalanceBox
                                    id="myBalances__tuitionBalance"
                                    isDisplayBalance={
                                        this.state.isDisplayTuitionBalance
                                    }
                                    toggleIsDisplayBalance={
                                        this.toggleIsDisplayTuitionBalance
                                    }
                                    depositLink={studentAccount}
                                    buttonLabel="Make Payment"
                                    balance={
                                        this.state.balanceDue?.toFixed(
                                            2
                                        ) ?? 'N/A'
                                    }
                                    icon="/img/logos/tuitionBalance.svg"
                                    alt={this.state.altAttribute}
                                    title={this.titleCase(this.state.currentTerm)}
                                />
                            </Grid>
                            <Grid id={"myBalances__gridItem_laundryBalance"} item className="balanceGridItem">
                                <BalanceBox
                                    id="myBalances__laundryBalance"
                                    isDisplayBalance={
                                        this.state.isDisplayLaundryBalance
                                    }
                                    toggleIsDisplayBalance={
                                        this.toggleIsDisplayLaundryBalance
                                    }
                                    depositLink={cardCenterMonarch}
                                    buttonLabel="Make Deposit"
                                    balance={
                                        this.props.laundryAccount?.amount?.toFixed(
                                            2
                                        ) ?? 'N/A'
                                    }
                                    icon="/img/logos/laundry.svg"
                                    alt="Laundry"
                                    title="Laundry"
                                />
                            </Grid>
                            <Grid id={"myBalances__gridItem_monarchBalance"} item className="balanceGridItem">
                                <BalanceBox
                                    id="myBalances__monarchBalance"
                                    isDisplayBalance={
                                        this.state.isDisplayMonarchBalance
                                    }
                                    toggleIsDisplayBalance={
                                        this.toggleIsDisplayMonarchBalance
                                    }
                                    depositLink={cardCenterMonarch}
                                    buttonLabel="Make Deposit"
                                    balance={
                                        this.props.monarchPlus?.amount?.toFixed(
                                            2
                                        ) ?? 'N/A'
                                    }
                                    icon="/img/monarchCard.svg"
                                    alt="Monarch Card"
                                    title="Monarch Plus"
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Widget>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        laundryAccount: state.myDiningReducer.laundryAccount,
        monarchPlus: state.myDiningReducer.monarchPlus
    };
};

export default connect(mapStateToProps)(MyBalances);
