import {faEye, faEyeSlash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, IconButton, Typography, Stack} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';

class GpaDetails extends Component {
    state = {};

    render() {
        return (
            <>
                {(this.props.data && ( 
                <Stack direction="row" sx={{width: '100%'}} justifyContent={"center"} alignItems={"center"}>
                    <Typography
                        id={'gpaDetails__number_currentGpa'} component="p" variant="h4" style={{wordWrap: 'break-word'}} >
                        {this.props.isDisplayGPA ? this.props.data : '---'}
                    </Typography>
                    <IconButton id="myPrograms__button_toggleGPA" aria-label="hide-gpa" onClick={this.props.toggleIsDisplayGPA} >
                        <FontAwesomeIcon size={"sm"} id={`gpaDetails__icon_${this.props.isDisplayGPA ? 'eyeSlash' : 'eye'}`} icon={ this.props.isDisplayGPA ? faEyeSlash : faEye } />
                    </IconButton>
                </Stack>
                )) || (
                    <Typography
                        id={'gpaDetails__text_noGpaFound'}
                        component="p"
                        variant="large"
                        style={{wordWrap: 'break-word'}}
                    >
                        No GPA found
                    </Typography>
                )}
            </>
        );
    }
}

export default GpaDetails;
