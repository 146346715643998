import { Breadcrumbs, Tab, Tabs, Typography, Link } from '@mui/material';
import React, { Component } from 'react';
import Widgets from './Widgets';
import {Container} from 'reactstrap';
import { Box } from '@mui/system';
import MyApps from './myApps';

class Settings extends Component {
    state = {
        activeTab: 1,
        Tabs: ['myApps', 'Widgets'] //, 'System Preferences']
    }

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab});
    };

    render() {
        return <div className="myOdu__settings">
            <Container className="myOdu__container secondaryPage">
                <Typography component='h2'>Settings</Typography>

                <Breadcrumbs className="myOdu__breadcrumbs" id={"settings__breadCrumbs"} aria-label="breadcrumb">
                    <Link href="/">
                        Dashboard
                    </Link>
                    <Typography>Settings - {this.state.Tabs[this.state.activeTab - 1]}</Typography>
                </Breadcrumbs>

                <Tabs
                    value={this.state.activeTab}
                    aria-label="User Settings"
                    // variant="fullWidth"
                    className="myOdu__tabs"
                    onChange={this.onTabChange}
                >
                    {
                        this.state.Tabs.map((tabLabel, index) => {
                            return <Tab 
                                value = {index+1}
                                label = {tabLabel}
                            />
                        })
                    }
                </Tabs>
                
                <Box>
                    {this.state.activeTab === 1 && <MyApps />}
                    {this.state.activeTab === 2 && <Widgets />}
                </Box>
            </Container>
        </div>
    }
}

export default Settings;