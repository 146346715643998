import React, {Component} from 'react';
import {connect} from 'react-redux';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {Typography, Link, Button} from '@mui/material';
import {
    faHouse,
    faBallotCheck,
    faRightToBracket,
    faIdBadge,
    faUserSecret,
    faTriangleExclamation,
    faBrowsers,
    faFileUser,
    faGear,
    faPersonToDoor,
    faIdCard,
    faArrowRightToBracket
} from '@fortawesome/pro-light-svg-icons';
import MenuListItem from './List/MenuListItem';
import {
    saveImpersonation,
    toggleImpersonation
} from '../../Impersonation/actions';
import {
    checklists,
    profilePhoto,
    statusAlerts
} from '../../../DataAccessLayer/services';
import {getData} from '../../../DataAccessLayer';
import {
    saveStatusAlerts,
    saveStatusAlertsIsError
} from '../../StatusAlerts/actions';
import {saveChecklists, saveChecklistsIsError} from '../../Checklists/actions';
import {
    isUserAdmin,
    isUserAdvisor,
    isUserFacultyStaff,
    isUserPowerUser,
    isUserStudent
} from '../utils';
import WithRouter from '../Router/WithRouter';
import ExpandableMenuListItem from './List/ExpandableMenuListItem';
import PortalTooltip from '../../common/PortalTooltip';
import PortalAvatar from './PortalAvatar';
import {identifyUser, logIn, logOut} from '../../../AWS';
import {saveUser} from '../../../AWS/actions';

export const isProfileUrlValid = img => {
    if (img == null)
        // if img is null or undefined
        return false;
    if (img.includes('placeholder')) return false;
    return true;
};

class Navigation extends Component {
    state = {
        img: '',
        isStatusAlertsLoading: false,
        isChecklistsLoading: false
    };
    logOut = () => {
        this.props.saveUser({});
        this.props.saveImpersonation({});
        logOut();
    };
    componentDidMount() {
        if (!this.props.statusAlerts && !this.props.isStatusAlertsError) {
            this.loadStatusAlerts();
        }
        if (!this.props.checklists && !this.props.isChecklistsError) {
            this.loadChecklists();
        }
        // TODO: Add the photo details to redux to avoid making this call everytime the screen is resized to mobile size or vice versa
        this.loadProfilePhoto();
    }

    checkIsActive = page => {
        const pathName = window.location.pathname;
        // The pathName for home page is '/', and the pathName for the rest of the pages starts with '/', Eg: for checklists page it is '/checklists' and for admin page it is '/admin' etc.
        // Using just the else condition (without the if block) to detemine the active tab, is always returning true for home tab even though the user is not on the home page, because the value passed to this method for home tab is '/' and this is included in the pathNames of all the pages.
        // Hence, handled the home page scenario in a separate if block.
        if (page === '/') {
            // Home page
            return pathName === '/';
        } else {
            // Rest of the pages
            return pathName.toLowerCase().includes(page.toLowerCase());
        }
    };

    loadStatusAlerts = () => {
        this.setState({
            isStatusAlertsLoading: true
        });
        getData(statusAlerts, true)
            .then(statusAlerts => {
                this.props.saveStatusAlerts(statusAlerts); // Also marks Status Alerts isError to false in the redux
            })
            .catch(err => {
                this.props.saveStatusAlertsIsError(true);
                console.log(err);
            })
            .finally(() => this.setState({isStatusAlertsLoading: false}));
    };

    loadChecklists = () => {
        this.setState({
            isChecklistsLoading: true
        });
        getData(checklists, true)
            .then(checklists => {
                this.props.saveChecklists(checklists?.checklists); // Also marks Checklists isError to false in the redux
            })
            .catch(err => {
                this.props.saveChecklistsIsError(true);
                console.log(err);
            })
            .finally(() => this.setState({isChecklistsLoading: false}));
    };

    loadProfilePhoto = () => {
        getData(profilePhoto).then(result => {
            this.setState({
                img: result?.img
            });
        });
    };
    onClickNavigationItem = navigateTo => {
        //if on mobile, close the menu
        if (this.props.isMobile) {
            //This could potentially give some user feedback about the menu.
            //side effect is that if user had sideNav open, shrunk window then
            //navigated somewhere on mobile menu, THEN enlarged window their
            //side nav would be closed.
            //BUT it does keep the state of the menu consistent.
            //will address later if issue
            this.props.onToggle();
        }
        this.props.navigate(navigateTo);
    };

    identifyUser = async () => {
        const user = await identifyUser();
        console.log('User:', user);
        this.props.saveUser(user);
    };

    render() {
        const isLoggedIn = this.props.user?.firstName ? true : false;
        const user = this.props.impersonation?.isImpersonating
            ? this.props.impersonation
            : this.props.user;
        const isValidUrl = isProfileUrlValid(this.state.img);
        const isPowerUser = isUserPowerUser(user);
        const isAdmin = isUserAdmin(user);
        const isFacultyStaff = isUserFacultyStaff(user);
        const isAdvisor = isUserAdvisor(user);
        const isStudent = isUserStudent(user);
        const isChecklistAvailable =
            !this.state.isChecklistsLoading &&
            !this.props.isChecklistsError &&
            this.props.checklists?.length > 0;

        return (
            <React.Fragment>
                <nav
                    className={
                        'myOdu__navigation ' +
                        (this.props.isMobile ? 'mobile ' : 'desktop ') +
                        (this.props.data.isOpen ? 'open ' : ' closed')
                    }
                >
                    <PortalAvatar
                        isMenuOpen={this.props.data.isOpen}
                        isLoggedIn={isLoggedIn}
                        user={user}
                        isValidUrl={isValidUrl}
                        img={this.state.img}
                        isMobile={this.props.isMobile}
                    />

                    {/* {this.props.isTopNav && <>
                    <Divider style={{backgroundColor: 'white'}}/>
                </>
                } */}

                    <Divider style={{backgroundColor: 'white'}} />

                    <List sx={{pt: 0}}>
                        {!isLoggedIn && (
                            <MenuListItem
                                data={{
                                    title: 'Sign In',
                                    isOpen: this.props.data.isOpen
                                }}
                                icon={faRightToBracket}
                            />
                        )}

                        {isLoggedIn && (
                            <React.Fragment>
                                {(isPowerUser || isAdmin) && (
                                    <React.Fragment>
                                        <MenuListItem
                                            title="Admin Panel"
                                            isOpen={this.props.data.isOpen}
                                            icon={faIdBadge}
                                            onClick={() =>
                                                this.props.navigate('/admin')
                                            }
                                            isActive={this.checkIsActive(
                                                'admin'
                                            )}
                                        />
                                        {isAdmin && (
                                            <>
                                                <MenuListItem
                                                    role="button"
                                                    title="Impersonate"
                                                    isOpen={
                                                        this.props.data.isOpen
                                                    }
                                                    icon={faUserSecret}
                                                    onClick={
                                                        this.props
                                                            .toggleImpersonation
                                                    }
                                                />

                                                <MenuListItem
                                                    className="d-block d-sm-none"
                                                    role="button"
                                                    title="Identify User"
                                                    isOpen={
                                                        this.props.data.isOpen
                                                    }
                                                    icon={faIdCard}
                                                    onClick={this.identifyUser}
                                                />
                                                {/* <MenuListItem
                                                className="d-block d-sm-none"
                                                role="button"
                                                title="Release Version"
                                                isOpen={this.props.data.isOpen}
                                                icon={faUserSecret}
                                                onClick={ this.props .toggleImpersonation }
                                            /> */}
                                            </>
                                        )}
                                        <li>
                                            <Divider
                                                style={{
                                                    backgroundColor: 'white'
                                                }}
                                            />
                                        </li>
                                    </React.Fragment>
                                )}

                                <MenuListItem
                                    className="d-block d-sm-none"
                                    title="My Info"
                                    isOpen={this.props.data.isOpen}
                                    icon={faFileUser}
                                    onClick={() =>
                                        this.onClickNavigationItem('/myInfo')
                                    }
                                    isActive={this.checkIsActive('myInfo')}
                                />

                                <MenuListItem
                                    className="d-block d-sm-none"
                                    title="Settings"
                                    isOpen={this.props.data.isOpen}
                                    icon={faGear}
                                    onClick={() =>
                                        this.onClickNavigationItem('/settings')
                                    }
                                    isActive={this.checkIsActive('settings')}
                                />

                                <li className="d-block d-sm-none">
                                    <Divider
                                        style={{backgroundColor: 'white'}}
                                    />
                                </li>

                                <MenuListItem
                                    title="Home"
                                    isOpen={this.props.data.isOpen}
                                    icon={faHouse}
                                    onClick={() =>
                                        this.onClickNavigationItem('/')
                                    }
                                    isActive={this.checkIsActive('/')}
                                />

                                <MenuListItem
                                    title="Status Alerts"
                                    isOpen={this.props.data.isOpen}
                                    icon={faTriangleExclamation}
                                    badgeCount={
                                        !this.state.isStatusAlertsLoading &&
                                        this.props.isStatusAlertsError
                                            ? '?'
                                            : this.props.statusAlertsCount
                                    }
                                    onClick={() =>
                                        this.onClickNavigationItem(
                                            '/statusAlerts'
                                        )
                                    }
                                    isActive={this.checkIsActive(
                                        'statusAlerts'
                                    )}
                                />
                                <MenuListItem
                                    title="Checklists"
                                    isOpen={this.props.data.isOpen}
                                    icon={faBallotCheck}
                                    variant={isChecklistAvailable ? 'dot' : ''}
                                    badgeCount={
                                        !this.state.isChecklistsLoading &&
                                        this.props.isChecklistsError
                                            ? '?'
                                            : isChecklistAvailable
                                            ? ''
                                            : 0
                                    }
                                    onClick={() =>
                                        this.onClickNavigationItem(
                                            '/checklists'
                                        )
                                    }
                                    isActive={this.checkIsActive('checklists')}
                                />
                                <ExpandableMenuListItem
                                    isSideNavOpen={this.props.data.isOpen}
                                    isMobile={this.props.isMobile}
                                    icon={faBrowsers}
                                    title="Systems"
                                    isAdvisor={isAdvisor}
                                />
                            </React.Fragment>
                        )}

                        <li className="d-block d-sm-none">
                            <Divider style={{backgroundColor: 'white'}} />
                        </li>

                        <MenuListItem
                            className="d-block d-sm-none"
                            title="Sign Out"
                            isOpen={this.props.data.isOpen}
                            icon={faPersonToDoor}
                            onClick={() => this.logOut()}
                        />

                        {isAdmin && (
                            <MenuListItem
                                className="d-block d-sm-none"
                                role="button"
                                title="Sign In"
                                isOpen={this.props.data.isOpen}
                                icon={faArrowRightToBracket}
                                onClick={() => logIn()}
                            />
                        )}
                    </List>
                </nav>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation,
        statusAlerts: state.myStatusAlertsReducer.statusAlerts,
        statusAlertsCount: state.myStatusAlertsReducer.count,
        isStatusAlertsError: state.myStatusAlertsReducer.isError,
        checklists: state.myChecklistsReducer.checklists,
        isChecklistsError: state.myChecklistsReducer.isError
    };
};

const mapDispatchToProps = dispatch => ({
    toggleImpersonation: () => dispatch(toggleImpersonation()),
    saveStatusAlerts: statusAlerts => dispatch(saveStatusAlerts(statusAlerts)),
    saveStatusAlertsIsError: isError =>
        dispatch(saveStatusAlertsIsError(isError)),
    saveChecklists: checklists => dispatch(saveChecklists(checklists)),
    saveChecklistsIsError: isError => dispatch(saveChecklistsIsError(isError)),
    saveUser: user => dispatch(saveUser(user)),
    saveImpersonation: impersonation =>
        dispatch(saveImpersonation(impersonation))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithRouter(Navigation));
