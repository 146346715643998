export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const savePreferences = (preferences) => ({
    type: SAVE_PREFERENCES,
    payload: {preferences}
});

export const SAVE_SETUP = 'SAVE_SETUP';
export const saveSetup = (setup) => ({
    type: SAVE_SETUP,
    payload: {setup}
});